import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import "../../styles/navbar.css"
import { useI18next } from "gatsby-plugin-react-i18next"
import LocaleDropdown from "./localeDropdown"
import styled from "styled-components"

const Navbar = ({ siteTitle, menuLinks }) => {
  const { t, language } = useI18next()

  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: 960,
        padding: "0.5rem 1.0875rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      <h1 style={{ margin: 0 }}>
        <Link to="/" style={{ color: `#efefef`, textDecoration: `none` }}>
          {siteTitle}
        </Link>
      </h1>
      <div style={{ marginLeft: "auto" }}>
        {menuLinks.map(link => (
          <StyledLink
            className="underline"
            key={link.name}
            to={`${language !== "en" ? "/" + language : "" }/${link.link}`}
            style={{color: 'white'}}
          >
            {t(`navbar.${link.name}`)}
          </StyledLink>
        ))}
      </div>
      <LocaleDropdown />
    </div>
  )
}

Navbar.propTypes = {
  siteTitle: PropTypes.string,
}

Navbar.defaultProps = {
  siteTitle: `mano`,
}

const StyledLink = styled(Link)`
  padding-right: 0.9rem;
  padding-left: 0.9rem;
  
  @media only screen and (max-width: 480px) {
    padding-right: 0.7rem;
    padding-left: 0.7rem; 
  }

`

export default Navbar
