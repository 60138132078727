import React  from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGlobe } from "@fortawesome/free-solid-svg-icons"
import { Link } from "gatsby"
import { useI18next } from "gatsby-plugin-react-i18next"

function verbose(lng) {
  switch (lng) {
    case "en":
      return "🇬🇧 English"
    case "pt":
      return "🇵🇹 Português"
    case "fr":
      return "🇫🇷 Français"
    default:
      return null;
  }
}

const LocaleDropdown = () => {
  const { languages, changeLanguage } = useI18next()

  return (
    <div className="dropdown">
      <FontAwesomeIcon
        icon={faGlobe}
        style={{
          fontSize: 25,
          marginLeft: `1rem`,
        }}
      />
      <div className="dropdown-content">
        {languages.map(lng => (
          <Link
            className="underline"
            to="#"
            style={{
              display: "block",
              color: "black",
              paddingRight: `1rem`,
              paddingLeft: `1rem`,
            }}
            onClick={e => {
              e.preventDefault()
              changeLanguage(lng)
            }}
          >
            {verbose(lng)}
          </Link>
        ))}
      </div>
    </div>
  )
}
export default LocaleDropdown
