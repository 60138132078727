import React from "react"
import "../../styles/header.css"
import "../../styles/navbar.css"
import { useTranslation } from "react-i18next"

let dimmedColor = `rgba(226, 226, 226, 0.84)`
const myName = (
  <>
    <span style={{ color: dimmedColor }}>Al</span>exandre{" "}
    <span style={{ color: dimmedColor }}>Ramalho</span>
  </>
)

const Header = ({ children }) => {
  const { t } = useTranslation()

  return (
    <React.Fragment>
      <div className="header">
        {children}
        <h1 style={{ marginTop: 80 }}>
          {t("hello")}
          <span aria-label=":wave:" role="img">
            👋
          </span>
        </h1>
        <h2>
          {t("intro-1")} {myName}, <br /> {t("intro-2")}
        </h2>
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 24 150 28"
          preserveAspectRatio="none"
          shapeRendering="auto"
        >
          <defs>
            <path
              id="gentle-wave"
              d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z"
            />
          </defs>
          <g className="parallax">
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="0"
              fill="rgba(255,255,255,0.7"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="3"
              fill="rgba(255,255,255,0.5)"
            />
            <use
              xlinkHref="#gentle-wave"
              x="48"
              y="5"
              fill="rgba(255,255,255,0.3)"
            />
            <use xlinkHref="#gentle-wave" x="48" y="7" fill="#fff" />
          </g>
        </svg>
      </div>
    </React.Fragment>
  )
}

export default Header
